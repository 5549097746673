export function generateMailto(meta, response) {
    let subject = meta.email.subject

    let lines = response.split('\n')

    if (lines[0].startsWith("Subject:")) {
        subject = lines[0].replace('Subject:', '').trim()
        lines.splice(0, lines[1].trim().length ? 1 : 2)
    }

    lines = lines.filter(line => !line.startsWith('[') && !line.endsWith(']'))

    const queryParams = [`subject=${encodeURIComponent(subject)}`, `body=${encodeURIComponent(lines.join('\n'))}`]

    if (meta.email.cc) {
        queryParams.push(`cc=${meta.email.cc.join(',')}`)
    }
    if (meta.email.bcc) {
        queryParams.push(`bcc=${meta.email.bcc.join(',')}`)
    }

    return `mailto:${meta.email.to.join(',')}?${queryParams.join('&')}`
}
import { useEffect, useState } from 'react';
import './App.css';

import useAxios from 'axios-hooks'
import reactStringReplace from 'react-string-replace';
import { deepEqual, getRandomFields, random, getFieldsFromString } from './helpers';
import { generateMailto } from './email';

// TODO load from server
const meta = {
  title: '🐴 Ban Horse Carriages',
  externalPrompt: 'I am a {who} from Victoria, BC.\n\nHelp me write a {what} to the Victoria City Council urging them to ban horse carriages.\n\nEmphasize {why}.',
  email: {
    to: ['mayorandcouncil@victoria.ca'],
    cc: ['executive.office@tourismvictoria.com', 'c.kingsley@victoria.ca', 's.thompson@victoria.ca', 'b.nicholls@victoria.ca', 'jcaradonna@victoria.ca', 'ccoleman@victoria.ca', 'mdell@victoria.ca', 'mgardiner@victoria.ca', 'shammond@victoria.ca', 'skim@victoria.ca', 'kloughton@victoria.ca', 'Dave.Thompson@victoria.ca'],
    bcc: [],
    subject: 'Ban Horse Carriages'
  },
  fields: {
    what: ["letter", "email"],
    who: ["tourist", "new resident", "longtime resident", "concerned citizen"],
    why: ["the animal welfare issues", "the impact on motorists", "the impact on cyclists", "the danger to horses", "the danger to the public", "decisions made by other cities", "the message it sends to children", "how the practice is outdated"],
    variation: ["", "Write it differently this time.", "Write it more concisely this time.", "Write it using simpler language this time."]
  },
  dataPath: '/data/victoria-bc-ban-horse-carriages.json'
}

function App() {
  const [response, setResponse] = useState('')
  const [fields, setFields] = useState(getRandomFields(meta.fields))

  const [{ data, loading }] = useAxios(meta.dataPath)

  useEffect(() => {
    document.title = meta.title

    // Populate the response based on the fields
    if (data) {
      setResponse(data.find(res => deepEqual(res.fields, fields))?.response || 'Please a different combination of fields.')
    }
  }, [data, fields])

  function regenerateHiddenFields() {
    const visibleFields = getFieldsFromString(meta.externalPrompt)
    const hiddenFields = Object.keys(meta.fields).filter(field => !visibleFields.includes(field))

    const newFields = { ...fields }
    for (let field of hiddenFields) {
      newFields[field] = random(meta.fields[field].filter(value => value !== fields[field]))
    }

    setFields(newFields)
  }

  const prompt = reactStringReplace(meta.externalPrompt, /({[^}]+})/g, (match) => {
    const field = match.replace(/[{}]/g, '')
    const values = meta.fields[field]

    return <select value={fields[field]} onChange={e => setFields({
      ...fields,
      [field]: e.target.value
    })}>{values?.map(value => <option key={value} value={value}>{value}</option>)}</select>
  })

  return loading ? <div className="App">Just a sec...</div> : (
    <div className="App">
      <h1>{meta.title}</h1>

      <p>Click on the underlined fields below to customize your letter.</p>

      <em className="prewrap">
        <p>
          {prompt}
        </p>
      </em>

      <div className="response" id="response">
        {response}
      </div>

      <div className="warning">👋 Hey! After you copy this or click <em>Send Email</em>, don't forget to proofread the message and sign your name at the end!</div>

      <div className="toolbar">
        <button onClick={() => {
          regenerateHiddenFields()

          setTimeout(() => document.getElementById('response').scrollIntoView({ behavior: 'smooth' }), 100)
        }}>Regenerate</button>
        <button onClick={() => navigator.clipboard.writeText(response)}>Copy</button>
        <a href={generateMailto(meta, response)}><button>Send Email</button></a>
      </div>

      <details>
        <summary>AI ethics, environmental impact, and privacy</summary>
        <p>Unlike conventional AI, all of these responses have been generated using open-source software (i.e. not by evil corporations) using no fresh water and 100% renewable energy. This website is completely private: your data is never exposed to anyone, not even its creators.</p>
      </details>
    </div>
  );
}

export default App;

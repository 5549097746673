export function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}

export function random(array) {
    return array[Math.floor((Math.random() * array.length))];
}

export function getRandomFields(fields) {
    return Object.keys(fields).reduce((obj, key) => {
        obj[key] = random(fields[key])
        return obj
    }, {})
}

export function getFieldsFromString(str) {
    return [...str.matchAll(/\{([^}]+)\}/g)].map(match => match[1])
}